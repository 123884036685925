import React from 'react';
import Header from './Header';
import './Home.css'; // Uncomment this line to use the CSS

const Home = () => {
  const handleCallButtonClick = () => {
    window.location.href = 'tel: +15412418998'; // Replace +1234567890 with your phone number
  };


  return (
    <div>
      <Header /> {/* This includes the navigation and any other header content */}
      <div className="home-content">{/* Wrapper for the main content of the home page */}

            <h1>Nate Loker  </h1>
            <h2> Independent | Fair | Professional </h2>
            <img src="head_shot.jpg" alt="Nate Loker" className="headshot" />
            <h2> Digital Expertise Meets Local Knowledge</h2>
            <ul> 
            <p> I am an experienced realtor with local connections in the Columbia River Gorge. I come from
             a background in software development and digital marketing. This combination allows me to 
            offer an unparalleled experience to my clients. </p>
            </ul>
            <h2>Client Experience</h2>

            <div class="testimonial-grid">
                <div class="testimonial">
                    <p>"Working with Nate as my realtor was an absolute pleasure! His expertise and commitment
                     made the entire property buying process smooth and stress-free. Nate's deep knowledge of 
                     the local market was evident. His communication was impeccable, keeping me informed at every 
                     step and ensuring I felt confident throughout. What impressed me most was Nate's genuine 
                     dedication to finding me the perfect property. He listened attentively to my preferences 
                     and tirelessly searched until we found exactly what I was looking for. Nate's negotiation 
                     skills were exceptional, securing a great deal and making sure all my concerns were addressed. 
                     I would wholeheartedly recommend Nate to anyone looking to buy or sell a property. 
                     His professionalism, integrity, and personal touch make him an outstanding 
                     real estate agent who goes above and beyond for his clients."  - Mark Deforge</p>
                </div>
                <div class="testimonial">
                    <p>"Nate was so great to work with! He was very patient with us as we looked into three different houses. 
                    He has great knowledge in the real estate field and had great recommendations for us when it came to finding
                     out how much to offer, who to go through with inspections and consultations. If we ever need to purchase or sell another home we would absolutely reach out to Nate again." - Sandra Virgen</p>
                </div>
            </div>

            <h2>Contact Info </h2>
            <ul>
            <li>Cell: (541) 241-8998 </li>
            <li>Email: nloker1@gmail.com </li>
            </ul>
            <button className="call-button" onClick={handleCallButtonClick}>Call Now</button>


            <img src="gorge_photo2.jpg" className="gorge_photo"/>

      </div>

    </div>
  );
}

export default Home;
